import React from "react"
import PropTypes from "prop-types"
import Select from "react-select";

class SelectPic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedPic: this.props.preSelectedPic.value, selectedAsset: this.props.preSelectedPic.asset_url};
    this.handleChange = this.handleChange.bind(this);
    this.options = this.props.optionsPix;
    this.pic_url_base = '';
  }


  handleChange(event) {
    this.setState({selectedPic: event.target.value});

    function assetForPic(options_pix, selected_pic) {
       return options_pix.find(function(options_pic) { return options_pic.label === selected_pic;})
    }

    var filter = assetForPic(this.options, event.target.value);
    this.setState({selectedAsset: filter.asset_url});
  }

  render () {

    let option_list = this.options.map(option => {
      return <option key={option.label} value={option.value} asset_url={option.asset_url}>{option.value}</option>
    });
    let select_name = this.props.content_type+'[pix_cr]['+this.props.pic_type+'][file]';
    let select_id = this.props.content_type+'_pix_cr_'+this.props.pic_type+'_file';


    return (
      <div className='row'>
        <select
          name={select_name}
          id={select_id}
          className="form_control"
          value={this.state.selectedPic}
          onChange={this.handleChange} >
          {option_list}
        </select>
        <div>
          <img src={this.state.selectedAsset} className={select_id} style={{height: '125px',margin: '4px',}} />
        </div>
      </div>
       );
  }
}

export default SelectPic
