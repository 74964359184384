// TODO merge with MobileMenu (Menu should collapse when search is extended)
// is active X icon

import React, {useState} from 'react';
import styled from 'styled-components';


const LangButton = styled.a`
    font-family: TheSansUHHSemiLightCaps;
    > span::after {
      content:  ${p => p.active ? "'x'" : "'\\142F'" } ;
      border: 0;
      transform: rotate(90deg);
      top: 0 !important;
      font-size: ${p => p.active ? '17px' : '13px'} ;
      margin-bottom: -2px;
      margin-left: 3px;
      background: none;
      width: auto;
      text-decoration: none;
    }
    height: 30px;
    line-height: 28px;
    float: left;
    color: #fff;
    text-decoration: none;
    @media screen and (max-width: 1199px){
      transform: rotate(0);
      top: 0px;
      line-height: 43px;
      margin: 0;
      width: 40px;
      height: 45px;
      display: block;
      font-size: 17px;

    }
`;

const LangMenuItems = styled.ul`
    display: ${p => p.active ? 'block' : 'none'} !important;
`;

const MobileSearchButton = styled.div`
    > a:after {
      background-image: unset;
    }
    > a {
      background-image: url("${p => p.active ? 'https://assets.rrz.uni-hamburg.de/assets/UHH_Icon-x_31x31-1ba423a4855ca9fee649e9fd673bd80ed494d306bd31f3fe2f11abfe5c731253.png' : 'https://assets.rrz.uni-hamburg.de/assets/Lupe_weiss_mobil-3f1627b723e58cc25156acaf478f47267de0cdcb1f39296fe975b6f082bcebba.svg'}");
      background-size: 25px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1100 !important;
    }
    @media(max-width: 1199px){
      opacity: 100 !important;
      position: relative  !important;
    }
`;

const MobileSearchForm = styled.div`
    border-top: 1px solid #fff;
    transition: opacity 0.25s;
    position: absolute;
    right: 0;
    width: 100%;
    padding: 20px 20px 24px 20px;
    font-size: 14px;
    opacity: ${p => p.active ? '1' : '0'} !important;
    form {
      opacity: ${p => p.active ? '1' : '0'} !important;
      top: ${p => p.active ? '45px' : '-200px'} !important;
    }
    button {
      width: 30px;
      height: 40px;
      border: 0;
      background: #fff;
      background-position-x: 0%;
      background-position-y: 0%;
      background-repeat: repeat;
      background-image: none;
      background-size: auto;
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 0;
      color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px;
      background-image: url(https://assets.rrz.uni-hamburg.de/assets/pfeil-blaugross-f9d1dd8be475563b57c51cada452e978329db0c5387bee25c4a8a071c46dbcfb.svg);
    }
`;

const MobileMenuIcon =styled.div`
    float: left;
    width: 50px;
    height: 45px;
    display: block;
    position: relative;
    text-align: center;
    background-image: url("${p => p.active ? 'https://assets.rrz.uni-hamburg.de/assets/UHH_Icon-x_31x31-1ba423a4855ca9fee649e9fd673bd80ed494d306bd31f3fe2f11abfe5c731253.png' : 'https://assets.rrz.uni-hamburg.de/assets/UHH_web_hamburger-menu_31x31tiny-21f4893d2e5a63b554c870c0a44b25e47b01034cc6766ebb77af216b7b3e0774.png'}");
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
    }
    @media(min-width: 1200px) {
        display:none;
    }
`;

const Menu = styled.nav`
    display: ${p => p.active ? 'block' : 'none'} !important;
    .mobilemenu-items {
      right: 0;
      transition: opacity 0.25s,right 0.1s;
      opacity: 1;
      background: #0271bb;
      font-family: TheSansUHHSemiLightCaps;
      font-size: 19px;
      overflow: hidden;
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: 420px;
      z-index: 100000;
      height: calc(100% - 45px);
      border-top: 1px solid #fff;

      ul > li {
            border-bottom: 1px solid #fff;
            display: table;
            width: 100%;
            > a {
                display: table-cell;
                vertical-align: middle;
                height: 45px;
                padding: 5px 10px 5px 14%;
                width: calc(100% - 50px);
                color: #fff;
            }
          }
      ul > li.here > a{
        padding: 5px 10% 5px 8%;
        font-family: TheSansUHHBoldCaps;
        }
      ul > li.top > a{
        padding: 5px 10% 5px 4%;
        font-family: TheSansUHHBoldCaps;
      }
        .sprachen2 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
         > a {
          display: block !important;
          padding: 5px 10px 5px 14%;
          background-position: 23px center;
         }
        }
        }
      }
    }
    @media(min-width: 768px){
        position: relative;
        width: initial;
        border-bottom: none;
        margin: auto 0 auto auto;
        background: none;
        left: initial;
        top: initial;
        display: flex;
    }
`;


class NavRechtsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search_active: '', lang_active: '', mm_active: ''};
    this.toggleSearch = this.toggleSearch.bind(this);
    this.find_all_path = this.props.find_all_path;

    this.toggleLang = this.toggleLang.bind(this);
    this.url_for_en = this.props.url_for_en;
    this.url_for_de = this.props.url_for_de;
    this.cur_locale = this.props.cur_locale;

    this.mm_items = this.props.mm_items;

    this.wortmarke = this.props.wortmarke;
    this.toggleMenu = this.toggleMenu.bind(this);
}

toggleSearch = () => {

  // function that will toggle active/false
    var toggle = this.state.search_active === true ? false : true;
    this.setState({search_active: toggle});
    this.setState({lang_active: false, mm_active: false});
  }

  toggleLang = () => {

  // function that will toggle active/false
    var toggle = this.state.lang_active === true ? false : true;
    this.setState({lang_active: toggle});
    this.setState({search_active: false, mm_active: false});
  }

toggleMenu = () => {

  // function that will toggle active/false
    var toggle = this.state.mm_active === true ? false : true;
    this.setState({mm_active: toggle});
    this.setState({search_active: false, lang_active: false});
    if (this.state.mm_active === true ) {
      document.body.style.overflowY = "scroll"
    }
    else {
      document.body.style.overflowY = "hidden"
    }
  }
  render(){
    let mm_list = Object.keys(this.mm_items).map(label => {
                         return <li key={label}> <a href={this.mm_items[label]}> {label} </a></li>
    });

  return(
        <ul>
          <li className="access">
            <a href="https://www.uni-hamburg.de/gebaerdensprache.html" className="gebaerdensprache">Gebärdensprache</a>
          </li>
          <li className="access">
            <a href="https://www.uni-hamburg.de/leichte-sprache.html" className="leichtesprache">Leichte Sprache</a>
          </li>
          <li className="sprache">
            <div>
              <div className="select-lang">
                <LangButton  active={this.state.lang_active} onClick={this.toggleLang} aria-controls="sprachauswahl" aria-expanded={this.state.active} aria-label="Sprachauswahl" href="#" role="button">
                  <span>{this.cur_locale}</span>
                </LangButton>
                <LangMenuItems  active={this.state.lang_active} className="sprachen" id="sprachauswahl">
                    <li><a href={this.url_for_de}>Deutsch</a></li>
                    <li><a href={this.url_for_en}>English</a></li>
                </LangMenuItems>
              </div>
            </div>
          </li>
          <li className="suche">
            <div>
              <section aria-label="Suche" id="suche" >
                <MobileSearchButton active={this.state.search_active} onClick={this.toggleSearch} >
                  <a href="#!"aria-label="sucheingabe öffnen" ></a>
                </MobileSearchButton>
                <MobileSearchForm active={this.state.search_active} className="mobile-search-form">
                  <form action={this.find_all_path} id="sucheingabe" >
                    <input aria-label="Suchbegriff" name="q" placeholder="..in Fundus suchen" tabIndex="0" type="text" id="inputx-0" />
                      <button type="button" aria-label="suchen" />
                      <input tabIndex="0" type="submit" value="Suchen" />
                    </form>
                  </MobileSearchForm>
                </section>
              </div>
            </li>
            <li >
                <MobileMenuIcon active={this.state.mm_active} onClick={this.toggleMenu} >
                </MobileMenuIcon>
                <Menu active={this.state.mm_active} >
                  <div className="mobilemenu-items">
                    <ul>
                      <li className="top">
                        <a href="/">Fundus</a>
                      </li>
                      {mm_list}
                    </ul>
                    <div className="sprachen2">
                      <a className="gebaerdensprache" href="https://www.uni-hamburg.de/gebaerdensprache.html" tabIndex="">Gebärdensprache</a>
                      <a className="leichtesprache" href="https://www.uni-hamburg.de/leichte-sprache.html" tabIndex="">Leichte Sprache</a>
                    </div>
                  </div>
                </Menu>
            </li>
        </ul>
    );
  }
}
export default NavRechtsList