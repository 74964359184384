import React from "react"
import ImageGallery from 'react-image-gallery';

class FundGallery extends React.Component {
  constructor(props) {
    super(props);
    this.images = this.props.images;
    this.showNavigation = this.images.length > 1;
  }

  render() {
    return <ImageGallery  items={this.images} showThumbnails={this.showNavigation} showPlayButton={this.showNavigation}/>;
  }
}

export default FundGallery