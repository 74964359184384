// TODO merge with MobileMenu (Menu should collapse when search is extended)
// is active X icon

import React, {useState} from 'react';
import styled from 'styled-components';

const StyledTopimage = styled.div`
  @media screen and (min-width: 0px) and (max-width: 640px) {
    background-image: url('${p => p.prev_pic}');
    .text{
      display: none;
    }
  }
  @media (min-width: 640px) and (max-width: 1199px) {
    .text{
      display: none;
    }
      background-image: url('${p => p.mid_pic}');
  }
  @media (min-width: 1200px) and (max-width: 6000px) {
      background-image: url('${p => p.title_pic}');
      .text {
        bottom: ${p => p.search_active ? '55px' : '80px' } !important;
        .beschreibung {
        display: ${p => p.search_active ? 'block' : 'none' };
      }
    }
  }

`;

class TopImage extends React.Component {
  constructor(props) {
    super(props);
    this.title_pix = {...this.props.title_pix}
  }

  render(){

  return(
    <StyledTopimage title_pic={this.title_pix.title_pic} mid_pic={this.title_pix.mid_pic}  prev_pic={this.title_pix.prev_pic} search_active={this.props.search_active} className="full-width-image" id="topimage">
      <div className="text">
        <h2>{this.props.page_title}</h2>
        <div className="beschreibung">{this.title_searchbox}
        <div className="fundus-suche" id="search-start">
          <form action="/de/search/find" acceptCharset="UTF-8" method="get">
            <input name="utf8" type="hidden" value="✓" />
            <input type="text" name="q" id="q" placeholder={this.props.placeholder_searchbox} aria-label={this.props.placeholder_searchbox} />
            <input type="hidden" name="include_content_objects" id="include_content_objects" value="true" />
            <input type="hidden" name="locale" id="locale" value="de" />
            <input type="submit" name="commit" value="{:result_count=>&quot;Ihre Suche nach '%{query}' ergab %{count} Treffer&quot;, :collection_count=>&quot;Aus dieser Sammlung werden insgesamt %{count} digitale Objekte in FUNDus angezeigt&quot;}" data-disable-with="{:result_count=>&quot;Ihre Suche nach '%{query}' ergab %{count} Treffer&quot;, :collection_count=>&quot;Aus dieser Sammlung werden insgesamt %{count} digitale Objekte in FUNDus angezeigt&quot;}" />
            </form>
          </div>
        </div>
      </div>
      <div className="bildnachweis">Foto: {this.props.title_pic_cc}</div>
    </StyledTopimage>
    );
  }
}
export default TopImage